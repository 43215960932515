import React from "react"
import EcomFormCep from "../../../EcomFormCep"

export default function AlterarEndereco() {
  return (
    <>
      <p className="mdn-Text mdn-Text--body">
        Informe o CEP e número do local de instalação.
      </p>

      <EcomFormCep
        checkBoxSideBySideWithNumber={false}
        vitrineSimplificada={true}
      />
    </>
  )
}
