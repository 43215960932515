import axios from "axios"
import * as M from "mondrian-react"
import {
  ChangeEvent,
  FormEvent,
  MutableRefObject,
  useEffect,
  useRef,
  useState
} from "react"

import { useAtom } from "jotai"
import jsonwebtoken from "jsonwebtoken"
import { useRouter } from "next/router"
import { useFeatureFlag, useForm, useTagging } from "../../hooks"
import useSessionStorage from "../../hooks/useSessionStorage"
import { ValidationKeysT } from "../../services/ValidationService"
import { CustomInput } from "../../shared"
import { storyblokAtom } from "../../store"
import { makeQueryString } from "../../utils"
import { sendLead } from "./lead-service"
import styles from "./styles.module.scss"
interface ComponentProps {
  checkBoxSideBySideWithNumber?: boolean
  vitrineSimplificada?: boolean
}
interface FormData {
  cep: string
  number: string
  name?: string
  email?: string
  phone?: string
}
interface IInputsConfig<T> {
  id: keyof T
  label: string
  defaultValue: T[keyof T]
  type: "text" | "number"
  noZeroLeft?: boolean
  validationType: ValidationKeysT
  focusAfterFill?: MutableRefObject<HTMLInputElement>
}

type FormVersion = "v0" | "v1" | "v2"
const formVersions: FormVersion[] = ["v0", "v1", "v2"]

export default function EcomFormCep(props: ComponentProps) {
  const formRef = useRef(null)
  const numberRef = useRef(null)
  const { query, push } = useRouter()
  const [checkboxValues, setCheckboxValues] = useState({
    hasNumber: false,
    hasInfoCheckbox: true
  })
  const [isLoading, setIsLoading] = useState(false)
  const [isRedirecting, setIsRedirecting] = useState(false)
  const [formVersion, setFormVersion] = useState<FormVersion>("v1")
  const [urlHash, setUrlHash] = useState('');

  const isVitrineSimplificada = props.vitrineSimplificada
    ? "vitrine-simplificada:modal-alterar-endereco"
    : ""
  const vitrineSimplificada = isVitrineSimplificada || "cep"
  const inputsByFormVersion = {
    v0: ["cep", "number"],
    v1: ["name", "phone", "info-checkbox", "cep", "number"],
    v2: ["name", "phone", "email", "info-checkbox", "cep", "number"]
  }[formVersion]
  const statesArray = [
    {
      id: 'name',
      defaultValue: '',
      label: 'nome',
      type: 'text',
      validationType: 'name',
    },
    {
      id: 'phone',
      defaultValue: '',
      label: 'celular',
      type: 'text',
      validationType: 'phone',
    },
    {
      id: 'email',
      defaultValue: '',
      label: 'email',
      type: 'text',
      validationType: 'email',
    },
    {
      id: 'cep',
      defaultValue: '',
      label: 'cep',
      type: 'text',
      validationType: 'cep',
      focusAfterFill: numberRef
    },
    {
      id: 'number',
      defaultValue: '',
      label: 'numero',
      type: 'text',
      validationType: 'number',
      maxLength: 8
    },
  ].filter(({ id }) => inputsByFormVersion.includes(id))

  const { getItem: getCroFormVersionItem, setItem: setCroFormVersionItem } =
    useSessionStorage("cro-form-version")
  const [storyblockData] = useAtom(storyblokAtom)
  const { featureFlag } = useFeatureFlag()
  const { checkmark, errors, handleBlur, handleChange, handleFocus, setCheckmark, state, setState, formIsValid, setErrors } = useForm({
    inputs: statesArray as IInputsConfig<FormData>[],
    tagging: {
      category: `plano-claro-res:${vitrineSimplificada}`
    }
  })
  const { handleTagging } = useTagging(`plano-claro-res:${vitrineSimplificada}`)

  const sendToExclusiveCheckout = async (cep, number, queryParams) => {
    const baseUrl = process.env.prospectUrl
    try {
      const { data } = await axios.get(
        `${baseUrl}/cobertura/api/available-technologies`,
        {
          headers: {
            cep: cep,
            number: number
          }
        }
      )

      const viability = (jsonwebtoken.decode(data) as { data: any }).data

      const isGpon = !!viability.technologies.find(
        (tech) => tech.gpon && tech.name === "Cable"
      )
      const isHFC = !!viability.technologies.find(
        (tech) => !tech.gpon && tech.name === "Cable"
      )
      const currTechnology = isGpon ? "gpon" : isHFC ? "hfc" : "dth"

      if (currTechnology === "dth") throw Error("DTH")

      push(`/checkout-exclusivo?${queryParams}`)
      setIsLoading(false)
    } catch (err) {
      const prospectUrl = process.env.prospectUrl
      window.location.href = `${prospectUrl}/monte-sua-combinacao?cep=${cep}&number=${number}`
    }
  }

  useEffect(() => {
    const onlyHash = window.location.hash.split('?')[0];
    setUrlHash(onlyHash);
  }, []);

  const handleFormSubmit = async (
    e: FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault()
    if (isLoading) return
    setIsLoading(true)
    try {
      const zipCode = state.cep.replace(" - ", "").split("-").join("")
      const urlVitrineSimplificada = `${process.env.prospectUrl}/vitrine?cep=${zipCode}&number=${state.number}`
      let urlMonteSuaCombinacao = `${process.env.prospectUrl}/monte-sua-combinacao?cep=${zipCode}&number=${state.number}`
      const lastRouteUrlBeforeRedirectToHome = sessionStorage.getItem(
        "lastRouteUrlBeforeRedirectToHome"
      )
      const originIdStorage = sessionStorage.getItem("originId")
      const affiliateId = query.affiliateId as string
      const products = Object.keys(query)
        .filter((key) => key.includes("Id"))
        .reduce((acc, key) => {
          acc[key] = query[key]
          return acc
        }, {})

      const croAffiliateId = {
        v1: "HJoI*dCzT",
        v2: "rygF*dAf6"
      }[formVersion]

      if (["v1", "v2"].includes(formVersion)) {
        try {
          const nameBs64 = Buffer.from(`${state.name}`).toString("base64")
          const phoneBs64 = Buffer.from(`${state.phone.replace(/\D/g, "")}`).toString(
            "base64"
          )
          const nameChars = `${nameBs64.substring(0, 10)}${nameBs64.substring(
            nameBs64.length - 10,
            nameBs64.length
          )}`
          const finalHash = `${nameBs64.length > 20 ? nameChars : nameBs64
            }${phoneBs64}`

          const hash = finalHash.replace(/[^\w\s]/gi, "")
          const emailMocked = `deadpooltestleads-${hash}@gmail.com`

          await sendLead({
            name: state.name,
            email: state.email || emailMocked,
            phone: state.phone || "11999999999",
            cep: state.cep,
            number: state.number,
            hasInfoCheckbox: checkboxValues.hasInfoCheckbox,
          })
          setIsRedirecting(true)
        } catch (err) {
          console.error(err)
        } finally {
          setIsLoading(false)
        }
      }

      if (
        lastRouteUrlBeforeRedirectToHome &&
        lastRouteUrlBeforeRedirectToHome.includes("dados-pessoais")
      ) {
        urlMonteSuaCombinacao = `${process.env.prospectUrl}/checkout/dados-pessoais?cep=${zipCode}&number=${state.number}`
      }

      if (
        lastRouteUrlBeforeRedirectToHome &&
        lastRouteUrlBeforeRedirectToHome.includes("personalizacao-movel")
      ) {
        urlMonteSuaCombinacao = `${process.env.prospectUrl}/checkout/personalizacao-movel?cep=${zipCode}&number=${state.number}`
      }

      if (
        storyblockData.data?.generalRules?.exclusiveCheckout?.affiliateIds?.includes(
          affiliateId
        )
      ) {
        const params = {
          cep: zipCode,
          number: state.number,
          affiliateId,
          ...products
        }
        const queryParams = makeQueryString(params)

        sendToExclusiveCheckout(zipCode, state.number, queryParams)
        return
      }

      if (originIdStorage) {
        urlMonteSuaCombinacao = `${urlMonteSuaCombinacao}&originId=${originIdStorage}`
      }

      if (urlHash != '') {
        urlMonteSuaCombinacao = `${urlMonteSuaCombinacao}${urlHash}`
      }

      const isFormBeingUsedOnModal = !props.checkBoxSideBySideWithNumber

      handleTagging({
        eventAction: "clique:botao",
        eventLabel: "consultar-disponibilidade"
      })

      window.location.href =
        isFormBeingUsedOnModal && props.vitrineSimplificada
          ? urlVitrineSimplificada
          : urlMonteSuaCombinacao

      setIsRedirecting(false)
    } catch (err) {
      console.error(err)
    }
    finally {
      setIsLoading(false)
    }
  }

  const checkCepWithNoNumber = (e: ChangeEvent<HTMLInputElement>): void => {
    const { checked } = e.target

    if (checked) {
      window.localStorage.setItem("hasNumber", "false")
    } else {
      window.localStorage.setItem("hasNumber", "true")
    }

    setState(prev => ({
      ...prev,
      number: checked ? "0" : ""
    }))
    setCheckmark(prev => ({
      ...prev,
      number: checked ? 'success' : 'none'
    }))
    setErrors(prev => ({
      ...prev,
      number: ''
    }))
    setCheckboxValues((prevState) => ({
      ...prevState,
      hasNumber: !prevState.hasNumber
    }))

    handleTagging({
      eventAction: `${checked ? "marcar" : "desmarcar"}:checkbox`,
      eventLabel: "sem-numero"
    })
  }

  const checkInfoBox = (e: ChangeEvent<HTMLInputElement>): void => {
    const { checked } = e.target

    if (checked) {
      window.localStorage.removeItem("hasInfoCheckbox")
    } else {
      window.localStorage.setItem("hasInfoCheckbox", "true")
    }
    setCheckboxValues((prevState) => ({
      ...prevState,
      hasInfoCheckbox: !prevState.hasInfoCheckbox
    }))

    handleTagging({
      eventAction: `${checked ? 'selecionar' : 'desmarcar'}:checkbox`,
      eventLabel: "info"
    })
  }

  const isFullyVisible = (element) => {
    const rect = element.getBoundingClientRect()
    const windowHeight = window.innerHeight
    return rect.top >= 0 && rect.bottom <= windowHeight
  }

  useEffect(() => {
    const formVersionFromStorage = getCroFormVersionItem() as FormVersion || 'v1'
    if (!featureFlag?.allowCtrlFormVersion) {
      setFormVersion('v0')
      return
    }

    if (formVersionFromStorage && formVersions.includes(formVersionFromStorage)) {
      setFormVersion(formVersionFromStorage)
      setCroFormVersionItem(formVersionFromStorage)
    }
  }, [featureFlag])

  useEffect(() => {
    if (formRef.current && !isFullyVisible(formRef.current))
      formRef.current.scrollIntoView({ block: "end", behavior: "smooth" })
  }, [])

  const inputs = {
    cep: (
      <div
        key="cep"
        className="mdn-Col-xs-12 mdn-Col-md-6"
        id="cep"
      >
        <CustomInput
          checkmark={checkmark.cep}
          id="cep"
          name="cep"
          type="text"
          value={state.cep}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          required
          hasError={errors.cep}
          errorMessage={errors.cep}
          aria-label="CEP"
          label="CEP*"
          mask="cep"
        />
      </div>
    ),
    number: (
      <div key="number" className="mdn-Col-xs-12 mdn-Col-md-6 mdn-Col-sm-12">
        <div className={`mdn-Row ${styles.center}`}>
          <div className="mdn-Col-xs-12">
            <CustomInput
              checkmark={checkmark.number}
              ref={numberRef}
              htmlFor="form"
              id="number"
              name="number"
              type="number"
              onBlur={handleBlur}
              onChange={handleChange}
              onFocus={handleFocus}
              value={state.number}
              label="Número*"
              hasError={errors.number}
              errorMessage={errors.number}
              disabled={checkboxValues.hasNumber}
            />
          </div>
          {featureFlag?.isCepSemNumeroEnabled && (
            <div className="mdn-Col-xs-12">
              <M.Checkbox
                name="checkbox"
                id="checkbox"
                checked={checkboxValues.hasNumber}
                value={"checked"}
                onChange={checkCepWithNoNumber}
                className={styles.mdnCheckbox}
                parentProps={{
                  htmlFor: "checkbox"
                }}
              >
                Meu endereço não possui número.
              </M.Checkbox>
            </div>
          )}
        </div>
      </div>
    ),
    "info-checkbox": (
      <div key="info-checkbox" className="mdn-Col-xs-12">
        <M.Checkbox
          name="info-checkbox"
          checked={checkboxValues.hasInfoCheckbox}
          onChange={checkInfoBox}
          className={styles.mdnCheckbox}
        >
          Aceito receber informações e status sobre meu pedido.
        </M.Checkbox>
      </div>
    ),
    email: (
      <div
        key="email"
        className="mdn-Col-xs-12 mdn-Col-md-12"
      >
        <CustomInput
          checkmark={checkmark.email}
          value={state.email}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          hasError={errors.email}
          errorMessage={errors.email}
          tooltipText="Caracteres acentuados e cedilhas não são permitidos no campo de e-mail."
          id="email"
          name="email"
          type="text"
          required
          aria-label="email"
          label="E-mail*"
        />
      </div>
    ),
    phone: (
      <div
        key="phone"
        className="mdn-Col-xs-12 mdn-Col-md-12"
      >
        <CustomInput
          checkmark={checkmark.phone}
          id="phone"
          name="phone"
          value={state.phone}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          required
          hasError={errors.phone}
          errorMessage={errors.phone}
          type="text"
          aria-label="phone"
          label="Celular*"
          mask="phone"
        />
      </div>
    ),
    name: (
      <div
        key="name"
        className="mdn-Col-xs-12 mdn-Col-md-12"
      >
        <CustomInput
          checkmark={checkmark.name}
          label="Nome Completo*"
          id="name"
          name="name"
          value={state.name}
          errorMessage={errors.name}
          hasError={errors.name}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
        />
      </div>
    )
  }

  const btnDisabled = !formIsValid || isLoading || isRedirecting
  return (
    <>
      <form
        id="form"
        ref={formRef}
        onSubmit={handleFormSubmit}
        className={styles.pageWrapper}
      >
        <div className="mdn-Row">
          {inputsByFormVersion.map((inputName) => inputs[inputName])}
        </div>
        <div className={`mdn-Row ${styles.btnConsultar}`}>
          <div className=" mdn-Col-xs-12 mdn-Col-sm-7 mdn-Col-md-6">
            <M.Button type="submit" block disabled={btnDisabled}>
              {isLoading
                ? "Consultando disponibilidade"
                : isRedirecting
                  ? "Redirecionando"
                  : "Consultar disponibilidade"}
            </M.Button>
          </div>
        </div>
      </form>
    </>
  )
}
