import React, { useEffect, useState } from "react"
import ModalService from "../../../../services/ModalService"
import AlterarEndereco from "../AlterarEndereco"
import { GeolocationResponse } from "../../../../types/GeolocationResponse"
import CroService from "../../../../services/CroService"

import styles from "./styles.module.scss"

interface ComponentProps {
  geolocationResponse: GeolocationResponse
}

export default function EsteESeuEndereco(props: ComponentProps) {
  const geolocationResponse: GeolocationResponse = props.geolocationResponse

  const [cep, setCep] = useState("")
  const [address, setAddress] = useState("")

  useEffect(() => {
    const formattedCEP = formatCep(geolocationResponse.zipCode)
    const formattedAddress = formatAddress(geolocationResponse.address)
    setCep(formattedCEP)
    setAddress(formattedAddress)
  }, [geolocationResponse.address, geolocationResponse.zipCode])

  function redirectToVitrineSimplificada(): void {
    CroService.postDataLayer({
      event: 'event',
      eventCategory: "planos-claro-res:cep:modal-este-e-seu-endereco",
      eventAction: "clique:botao",
      eventLabel: "sim-endereco-instalacao"
    })

    const zipCode = geolocationResponse.zipCode.replace(" - ", "").split("-").join("")
    const number = geolocationResponse.number
    const url = `https://planos.claro.com.br/vitrine?cep=${zipCode}&number=${number}`
    window.location.href = url
  }

  function handleOutroEndereco(): void {
    CroService.postDataLayer({
      event: 'event',
      eventCategory: "planos-claro-res:cep:modal-este-e-seu-endereco",
      eventAction: "clique:botao",
      eventLabel: "nao-outro-endereco"
    })

    ModalService.open({
      component: AlterarEndereco,
      componentProps: {},
      size: 'sm',
      showHeader: true,
      title: 'Alterar endereço de instalação',
      taguear: true,
      tags: {
        abertura: {
          event: 'event',
          eventCategory: "planos-claro-res:cep:modal-alterar-endereco-instalacao",
          eventAction: "modal:abriu",
          eventLabel: "alterar-endereco-instalacao"
        },
        fechamento: {
          event: 'event',
          eventCategory: "planos-claro-res:cep:modal-alterar-endereco-instalacao",
          eventAction: "modal:fechou",
          eventLabel: "alterar-endereco-instalacao"
        },
      }
    })
  }

  function formatCep(cep: string) {
    return cep
      .replace(/\D/g, "")
      .replace(/(\d{5})(\d)/, "$1-$2")
      .replace(/(-\d{3})\d+?$/, "$1")
  }

  function formatAddress(address: string) {
    const insertAt = address.search(/[0-9]/) - 1
    const formattedString = address.split("")
    formattedString.splice(insertAt, 0, ",")
    return formattedString.join("")
  }

  return (
    <>
      <p className="mdn-Text mdn-Text--body">
        Confirme se o endereço abaixo é o endereço de instalação.
      </p>

      <div className={styles.locationCard}>
        <span className="mdn-Icon-localizacao mdn-Icon--md"></span>
        <div>
          <h1 className="mdn-Heading mdn-Heading--xs">{`${address}`}</h1>
          <p className="mdn-Text mdn-Text--body">{`CEP: ${cep}`}</p>
        </div>
      </div>

      <div className={styles.btnsContainer}>
        <button className="mdn-Button mdn-Button--primary"
          onClick={() => redirectToVitrineSimplificada()}>
          Sim, é o endereço de instalação
        </button>

        <button className="mdn-Button mdn-Button--secondary"
          onClick={() => handleOutroEndereco()}>
          Não, é outro endereço
        </button>
      </div>
    </>
  )
}
