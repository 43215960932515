import CroService from "../../services/CroService"
import ModalService from "../../services/ModalService"
import JaSouClienteComponent from "../Modal/Modals/jornada-ja-sou-cliente/JaSouClienteComponent"

import styles from "./styles.module.scss"

interface ComponentProps {
  caption: string;
  children: string;
  storyBlok: unknown;
}

export default function JaEClienteBtn(props: ComponentProps) {
  function handleOnClick(): void {
    CroService.postDataLayer({
      event: 'event',
      eventCategory: "planos-claro-res:cep",
      eventAction: "clique:botao",
      eventLabel: "cliente-claro-residencial"
    })

    ModalService.open({
      size: 'lg',
      component: JaSouClienteComponent,
      componentProps: { props },
      showHeader: false,
      bannerMode: true,
      taguear: true,
      tags: {
        abertura: {
          event: 'event',
          eventCategory: 'planos-claro-res:cobertura:modal-cliente-claro-movel-beneficios',
          eventAction: 'modal:abriu',
          eventLabel: 'cliente-claro-movel-beneficios'
        },
        fechamento: {
          event: 'event',
          eventCategory: 'planos-claro-res:cobertura:modal-cliente-claro-movel-beneficios',
          eventAction: 'modal:fechou',
          eventLabel: 'cliente-claro-movel-beneficios'
        }
      }
    });
  }

  return (
    <button className={`${styles.card} ${styles.btnClienteClaro}`}
      onClick={handleOnClick}>
      <div>
        <span className="mdn-Icon-cliente-fixo mdn-Icon--md"></span>
        <div>
          <p className={`mdn-Text mdn-Text--body ${styles.bodyText}`}>
            {props.children}
          </p>
          <p className={`mdn-Text mdn-Text--caption ${styles.captionText}`}>
            {props.caption}
          </p>
        </div>
      </div>
      <span className="mdn-Icon-direita mdn-Icon--md"></span>
    </button>
  )
}
